
@import 'tailwindcss/base';
// @import "survey-core/defaultV2.min.css";
// @import "survey-creator-core/survey-creator-core.min.css";

$radius: 12px;
//IRANsans
@font-face {
  font-family: IRANsans;
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/IRANSansXFaNum-Regular.woff2) format('woff2')
}

@font-face {
  font-family: IRANsans;
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/IRANSansXFaNum-Bold.woff2) format('woff2')
}

//Vazir
@font-face {
  font-family: Vazir;
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Vazir-FD-WOL.woff2) format('woff2')
}

@font-face {
  font-family: Vazir;
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/Vazir-Bold-FD-WOL.woff2) format('woff2')
}

//Nastaliq

@font-face {
  font-family: Nastaliq;
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/IranNastaliq.woff2) format('woff2')
}

@font-face {
  font-family: Nastaliq;
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/IranNastaliq.woff2) format('woff2')
}


//BYekan
@font-face {
  font-family: BYekan;
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/B_Yekan_YasDL.com.ttf)
}

@font-face {
  font-family: BYekan;
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/Yekan.ttf)
}




.loader {
  z-index: 3000 !important;
}

.Toastify__toast-body {
  font-family: IRANsans;
}
