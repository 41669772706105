.titleForm {
    display: flex;
    justify-content: space-between;
    align-items: center
  }
  
  
  .formBody {
    display: flex;
    flex-direction: column;
    align-items: center
  }
  
  