.root {
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #1B5D6B;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #1B5D6B inset;
  transition: background-color 5000s ease-in-out 0s;
}
  
}
