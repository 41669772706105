.main{
    
    background: hsla(216, 25%, 19%, 1);

background: linear-gradient(90deg, hsla(216, 25%, 19%, 1) 10%, hsla(185, 84%, 38%, 1) 100%);

background: -moz-linear-gradient(90deg, hsla(216, 25%, 19%, 1) 10%, hsla(185, 84%, 38%, 1) 100%);

background: -webkit-linear-gradient(90deg, hsla(216, 25%, 19%, 1) 10%, hsla(185, 84%, 38%, 1) 100%);

// filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#252F3E", endColorstr="#0FA3B1", GradientType=1 );
   
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container{
    background-color: transparent !important;
    margin-bottom: 20px !important;
}

.Box{
    margin:20px 10px;
    padding:20px 10px;
    display:flex;
    flex-direction: column;
    align-items: center;
}