$primary-main: #0fa3b1;
$primary-dark: #252f3e;
$primary-light: #92dfef;
$text-color:rgb(63, 63, 63);

.sv_main {
    font-family: IRANsans !important;
}

.sv-title {
    font-family: IRANsans !important;
    // color:#0FA3B1
}

.sv-string-viewer {
    font-family: IRANsans !important;
    color: #6e6c6c !important
}

.sv-completedpage {
    font-family: IRANsans !important;
}

.sv-completedpage:before {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 8px !important;
}

.sv-header__text {
    color: $primary-main;
}

.sv-btn {
    background-color: $primary-main !important;
}

.sv-progress__bar{
    background-color: $primary-main !important;
}
.sv-item__svg .sv-radio__svg{
    color: #7c7a7a !important;
}
.sv-root-modern .sv-radio--checked .sv-radio__svg{
    border-color: $primary-main !important;
    fill:$primary-main !important;
}

.sv-root-modern .sv-radio__svg{
    border-color: #adadad !important;
    fill:#adadad !important;
}

.sv-question__num{
    color: #7c7a7a !important;
}

.sv-comment{
    color: #7c7a7a !important;
}